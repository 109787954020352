import 'dayjs/locale/ru.js'
import 'dayjs/locale/en.js'
import 'dayjs/locale/ky.js'

import { onBeforeMount, watch, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '~/common/src/stores/auth'
import useServicesVisibleStore from '~/common/src/stores/servicesVisible'
import useUISettingsStore from '~/common/src/stores/uiSettings'
import { useHead } from '@unhead/vue'
import { useI18n } from '~common/composables/useI18n'
import jivoApi from '~/common/src/api/jivo.api'

import dayjs from 'dayjs'
import { wlHostname } from '~common/utils/wl'
import useWatchAuthenticated from '~/common/src/composables/useWatchAuthenticated'

export default () => {
  const authStore = useAuthStore()
  const servicesVisibleStore = useServicesVisibleStore()
  const uiSettingsStore = useUISettingsStore()

  const domainParam = wlHostname(window.location.hostname)

  const { currentLocale, setLocale } = useI18n()

  const router = useRouter()
  const route = useRoute()
  const { t } = useI18n()

  const settingsIsFetched = ref(false)

  const appIsMaintenance = computed(
    () => servicesVisibleStore.servicesVisible.is_maintenance
  )

  const fetchSettings = async () => {
    await Promise.all([
      servicesVisibleStore.fetchPublicServicesVisible(domainParam),
      uiSettingsStore.fetchUISettings(domainParam)
    ])

    settingsIsFetched.value = true
  }

  const headData = computed(() => {
    const title = `${uiSettingsStore.uiSettings?.titleCompanyName} | ${t('app.title')}`

    return { title }
  })

  const initHead = () => {
    useHead(headData)
  }

  const initJivoWidget = (locale: string) => {
    const widgetId =
      locale === 'ru'
        ? import.meta.env.PUBLIC_JIVO_WIDGET_ID_RU
        : import.meta.env.PUBLIC_JIVO_WIDGET_ID_EN

    jivoApi.initVidget(widgetId)
  }

  const initFavicons = () => {
    const buccketUrl = import.meta.env.PUBLIC_BUCCKET_URL

    const createLink = (fileName: string) => {
      const link = document.createElement('link')

      link.type = 'image/png'
      link.rel = 'shortcut icon'

      document.head.appendChild(link)

      link.href = `${buccketUrl}/wl/${wlHostname(window.location.hostname)}/favicons/${fileName}`
    }

    createLink('favicon-16x16.png')
    createLink('favicon-32x32.png')
  }

  watch(
    [currentLocale, () => uiSettingsStore.uiSettings?.availableLocales],
    async ([localeVal, availableLocalesVal]) => {
      if (!localeVal || !availableLocalesVal?.length) return

      let locale = localeVal

      // сбрасываем на русский, если текущий язык отсутствует в доступных локалях для ВЛ
      if (!availableLocalesVal.includes(localeVal)) {
        locale = 'ru'
      }

      dayjs.locale(locale)

      await setLocale(locale)

      initJivoWidget(locale)

      if (!authStore.isAuthenticated) return

      await authStore.setLocale(locale)
    },
    { immediate: true }
  )

  watch(
    () => authStore.isAuthenticated,
    val => {
      if (!val || authStore.user.locale === currentLocale.value) return

      setLocale(authStore.user.locale)
    },
    { immediate: true }
  )

  watch(
    [settingsIsFetched, route, appIsMaintenance],
    ([settingsIsFetchedVal, routeVal, appIsMaintenanceVal]) => {
      if (authStore.user.is_maintenance || !settingsIsFetchedVal) return

      const technicalWorksPath = '/technical-works'

      if (!appIsMaintenanceVal && routeVal.path === technicalWorksPath) {
        router.push('/')

        return
      }

      if (appIsMaintenanceVal && routeVal.path !== technicalWorksPath) {
        router.push(technicalWorksPath)

        return
      }
    }
  )

  useWatchAuthenticated(() => {
    if (authStore.user.is_technical) return

    servicesVisibleStore.fetchServicesVisible(domainParam)
  })

  onBeforeMount(async () => {
    initFavicons()

    await fetchSettings()

    initHead()
  })
}
