import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IUserTask, TUserTaskOperationType } from '~/models/User'
import userApi from '~/api/userApi.api'
import useWatchAuthenticated from '~/common/src/composables/useWatchAuthenticated'
import { useAuthStore } from '~/common/src/stores/auth'

export const useTasksStore = defineStore('tasks', () => {
  const authStore = useAuthStore()

  const tasks = ref<IUserTask[]>([])

  const fetchUserTasks = async () => {
    tasks.value = await userApi.fetchUserTasks()
  }

  const getOperationTasksCount = (type: TUserTaskOperationType) => {
    return tasks.value.find(({ operation_type }) => operation_type === type)
      ?.amount
  }

  useWatchAuthenticated(() => {
    if (authStore.user.is_technical) return

    fetchUserTasks()
  })

  return {
    tasks,
    getOperationTasksCount
  }
})
